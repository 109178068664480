import { getNodesBounds, useReactFlow } from "@xyflow/react";
import { useEffect, useMemo, useState } from "react";
import { groupNodeIds } from "../helpers/groupNodeIds";
import useCenterNode from "./useCenterNode";
import useGlobalContext from "./useGlobalContext";

declare global {
  interface Window {
    navigateToNextNode: () => void;
    navigateToPreviousNode: () => void;
  }
}

export default function useNavigateToNode() {
  const [index, setIndex] = useState(0);

  const centerNode = useCenterNode();
  const { fitBounds, getNodes, setNodes } = useReactFlow();
  const { setIsInteracted } = useGlobalContext();

  const nodes = getNodes();

  const groupedNodeIds = useMemo(() => groupNodeIds(nodes), [nodes]);

  const navigateToNextNode = () => {
    const nextIndex =
      (((index === 0 || index) && index + 1) || 0) % groupedNodeIds.length;

    navigateToNodeOrNodes(nextIndex);
  };

  const navigateToPreviousNode = () => {
    const previousIndex = (index === 0 || index) && index - 1;

    if (previousIndex >= 0) return navigateToNodeOrNodes(previousIndex);

    const lastIndex = groupedNodeIds.length - 1;

    navigateToNodeOrNodes(lastIndex);
  };

  const navigateToNodeOrNodes = (index: number) => {
    setIndex(index);
    setIsInteracted(true);

    const nodeIdOrIds = groupedNodeIds[index];

    if (!nodeIdOrIds) return;

    if (Array.isArray(nodeIdOrIds)) {
      const nodesGroup = nodes.filter((node) => nodeIdOrIds.includes(node.id));

      const nodesBounds = getNodesBounds(nodesGroup);

      fitBounds(nodesBounds, { duration: 1000 });
    } else {
      const node = nodes.find((node) => node.id === nodeIdOrIds);

      centerNode(node);
    }

    unhideBranchNodes(nodeIdOrIds);
  };

  const unhideBranchNodes = (nodeIdOrIds: string | string[]) => {
    const nodeId = Array.isArray(nodeIdOrIds) ? nodeIdOrIds[0] : nodeIdOrIds;

    const nodeIdStartsWith = nodeId.split(".").slice(0, 2).join(".");

    if (nodeIdStartsWith.split(".").length < 2) return;

    setNodes((nodes) =>
      nodes.map((node) => {
        if (
          node.id.split(".").length > 2 &&
          node.id.startsWith(nodeIdStartsWith)
        ) {
          return { ...node, hidden: false };
        } else {
          return node;
        }
      }),
    );
  };

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data === "navigateToNextNode") {
        navigateToNextNode();
      }

      if (event.data === "navigateToPreviousNode") {
        navigateToPreviousNode();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.navigateToNextNode = () => {
      navigateToNextNode();
    };

    window.navigateToPreviousNode = () => {
      navigateToPreviousNode();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    navigateToNextNode,
    navigateToPreviousNode,
  };
}
